import React from "react";
import {PlaceProps} from "./marker";
import "./info_window.scss"

interface InfoWindow {
  place: PlaceProps;
}
export const InfoWindow: React.FC<InfoWindow> = ({place, ...props}) => {

  const {lat, lng, name, rating, types, price_level,opening_hours} = place;

  return (
    <div className="info-window">
      <div>
        <div style={{fontSize: 16}}>
          {name}
        </div>
      </div>
      <div style={{fontSize: 14}}>
        <span style={{color: 'grey'}}>
          {rating}
          {' '}
        </span>
        <span style={{color: 'orange'}}>
          {String.fromCharCode(9733).repeat(Math.floor(rating))}
        </span>
        <span style={{color: 'lightgrey'}}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(rating))}
        </span>
      </div>
      {/*<div style={{fontSize: 14, color: 'grey'}}>
        {types[0]}
      </div>
      <div style={{fontSize: 14, color: 'grey'}}>
        {'$'.repeat(price_level)}
      </div>
      <div style={{fontSize: 14, color: 'green'}}>
        {opening_hours.open_now ? 'Open' : 'Closed'}
      </div>*/}
    </div>
  )
}