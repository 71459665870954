import React from "react";
import {useTranslation} from "react-i18next";

export const SocialButtons = () => {
  const {t} = useTranslation();

  return (
    <div>
      <ul className="app-buttons">
        <li>
          <a className="button application android"
             href="https://play.google.com/store/apps/details?id=gr.eworx.rendev.uv.skeptron">{t('common.download.google')}</a>
        </li>

        <li>
          <button className="application apple">{t('common.download.apple')}</button>
        </li>
      </ul>
    </div>
  )
}