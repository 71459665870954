import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useKeenSlider} from "keen-slider/react";
import 'keen-slider/keen-slider.min.css';
import {Section} from "../common/section";
import "./carousel.scss";
import {ArrowLeft, ArrowRight, MiddleText} from "../common/carousel/carousel";
import GatsbyImage from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {Link} from "gatsby-plugin-react-i18next";

export const Carousel: React.FC = () => {

  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const [pause, setPause] = useState(false);
  const timer = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    duration: 2000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    }
  });

  useEffect(() => {
    slideRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    slideRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [slideRef])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 3000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  const [markdownText, setMarkdownText] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    const fetchMarkdown = async (): Promise<void> => {
      const markdownFile1 = await import(
        `./carousel/skeptron_l.${currentLanguage}.md`
        );
      const markdownFile2 = await import(
        `./carousel/skeptron_car.${currentLanguage}.md`
        );
      const markdownFile3 = await import(
        `./carousel/skeptron_hybrid.${currentLanguage}.md`
        );
      const markdownFile4 = await import(
        `./carousel/skeptron_t.${currentLanguage}.md`
        );
      const markdownFile5 = await import(
        `./carousel/skeptron_r.${currentLanguage}.md`
        );
      const markdownFile6 = await import(
        `./carousel/skeptron_pro.${currentLanguage}.md`
        );
      setMarkdownText([markdownFile1, markdownFile2, markdownFile3, markdownFile4, markdownFile5, markdownFile6]);
      setIsLoading(false);
    };
    fetchMarkdown();
  }, [currentLanguage]);

  const data = useStaticQuery(graphql`
  query {
  skeptron_l: file(relativePath: {eq: "carousel/waiting-room.jpg"}) { ...carouselImage }
  car: file(relativePath: {eq: "carousel/wedding-accessories-interior-saint-trinity-orthodox-convent.jpg"}) { ...carouselImage }
  hybrid: file(relativePath: {eq: "carousel/markus-spiske-J_tbkGWxCH0-unsplash.jpg"}) { ...carouselImage }
  skeptron_t: file(relativePath: {eq: "carousel/airplane-flying-past-departures-lounge-window.jpg"}) { ...carouselImage }
  skeptron_r: file(relativePath: {eq: "carousel/airplane-flying-past-departures-lounge-window.jpg"}) { ...carouselImage }
  pro: file(relativePath: {eq: "carousel/gym-equipments-fitness-club.jpg"}) { ...carouselImage }  
  }`)

  // Check example at: https://codesandbox.io/s/keen-slider-navigation-react-pbmic?file=/src/App.js
  return (
    <Section className="carousel">
      <div className="navigation-wrapper">
        

        <div ref={slideRef} className="keen-slider">
          <div className="keen-slider__slide">
            {markdownText[0] ?
              <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[0].html}}/> : null
            }
            <div className="slider-image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.skeptron_l.childImageSharp.fluid}
              />
            </div>
            <Link to="/products/skeptron/skeptron_l">{t('home.skeptron.button')}</Link>
          </div>
          <div className="keen-slider__slide">
            {markdownText[1] ?
              <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[1].html}}/> : null
            }
            <div className="slider-image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.car.childImageSharp.fluid}
              />
            </div>
            <Link to="/products/skeptron/car">{t('home.skeptron.button')}</Link>
          </div>
          <div className="keen-slider__slide">
            {markdownText[2] ?
              <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[2].html}}/> : null
            }
            <div className="slider-image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.hybrid.childImageSharp.fluid}
              />
            </div>
            <Link to="/products/skeptron/hybrid">{t('home.skeptron.button')}</Link>
          </div>
          <div className="keen-slider__slide">
            {markdownText[3] ?
              <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[3].html}}/> : null
            }
            <div className="slider-image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.skeptron_t.childImageSharp.fluid}
              />
            </div>
            <Link to="/products/skeptron/skeptron_t">{t('home.skeptron.button')}</Link>
          </div>
          {/* UV-171: Remove slide skeptron r */}
          {/*<div className="keen-slider__slide">*/}
          {/*  {markdownText[4] ?*/}
          {/*    <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[4].html}}/> : null*/}
          {/*  }*/}
          {/*  <div className="slider-image">*/}
          {/*    <GatsbyImage*/}
          {/*      style={{height: "100%", width: "100%"}}*/}
          {/*      fluid={data.skeptron_r.childImageSharp.fluid}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <Link to="/products/skeptron/skeptron_r">{t('home.skeptron.button')}</Link>*/}
          {/*</div>*/}
          <div className="keen-slider__slide">
            {markdownText[5] ?
              <div className="slider-text" dangerouslySetInnerHTML={{__html: markdownText[5].html}}/> : null
            }
            <div className="slider-image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.pro.childImageSharp.fluid}
              />
            </div>
          </div>
          <Link to="/products/skeptron/pro">{t('home.skeptron.button')}</Link>
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />
            <MiddleText
              currentSlide={currentSlide}
              slider={slider}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </div>
      {/*{slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx)
                }}
                className={`dot${currentSlide === idx ? " active" : ""}`}
              />
            )
          })}
        </div>
      )}*/}
    </Section>
  )
}
