import React, {useCallback, useEffect, useState} from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import {Button} from "../common/buttons/button";
import {Heading} from "../common/heading";
import {animated, useSpring} from "react-spring";
import {PromoSection} from "../common/promo_section";
import GatsbyImage from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";

const calc = (x, y) => [0, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 20 - 200}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`
const transY = (x, y) => `translateY(${y}px)`

export const Introduction: React.FC = () => {
  // const [{st}, set] = useSpring(() => ({ st: 300, config: { mass: 50, tension: 550, friction: 140 } }))
  const [{st, xy}, set] = useSpring(() => ({st: 200, xy: [0, 0]}))
  const {t} = useTranslation();

  // const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => set({st: window.scrollY});
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
  //   springscrollY: 0
  // }));

  // const parallaxLevel = 1
  // springsetScrollY({ springscrollY: scrollY });
  // const interpHeader = springscrollY.interpolate(o => `translateY(${o / parallaxLevel - 200}px)`);
  const interp1 = st.interpolate(o => `translateY(${o / 4})`)

  // const onScroll = useCallback(e => {
  //   console.log(`scrolling ${e.target.scrollTop / 30}`)
  //   set({ st: e.target.scrollTop / 30 })
  // }, [])
  // ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}

  const data = useStaticQuery(graphql`
  query {
  promo1: file(relativePath: {eq: "promo/home1.jpg"}) { ...promoImage }
  promo2: file(relativePath: {eq: "promo/home2.jpg"}) { ...promoImage } 
  }`)

  const promoImage = graphql`
  fragment promoImage on File {
    childImageSharp {
      fluid(fit: INSIDE) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }`

  return (
    <PromoSection>
      <div className="page-promo-text">
        <animated.h1 style={{transform: interp1}}>{t("home.introduction.heading")}</animated.h1>
        <p>{t("home.introduction.description")}</p>
        <Link className="button" to="/products/skeptron">{t("home.introduction.welcome")}</Link>
        <Link className="play" to="/faq">{t("home.introduction.information")}</Link>
      </div>
      <div className="page-promo-image">
        <div className="image-holder">
          <div className="image">
            <GatsbyImage
              style={{height: "100%", width: "100%"}}
              fluid={data.promo1.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="image-holder small">
          <div className="image">
            <GatsbyImage
              style={{height: "100%", width: "100%"}}
              fluid={data.promo2.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>


    </PromoSection>
  )
}
