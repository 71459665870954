import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../common/section";
import {SocialButtons} from "../common/buttons/social";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Benefits: React.FC = () => {
  const {t} = useTranslation();
  const items = [0, 1, 2];

  const data = useStaticQuery(graphql`
  query {
  benefits: file(relativePath: {eq: "home/benefits.png"}) {
    childImageSharp {
      fluid(fit: INSIDE) {
        ...GatsbyImageSharpFluid_withWebp        
      }
    }
  }}`)

  return (
    <Section className="benefits">
      <p className="subheading">{t('home.benefits.subheading')}</p>
      <h2>{t('home.benefits.heading')}</h2>

      <div className="app">

        <div className="app-image">
          <GatsbyImage
            style={{height: "100%", width: "100%"}}
            fluid={data.benefits.childImageSharp.fluid}
          />
        </div>

        <div className="app-info">
          <h3 className="bluetooth">{t('home.benefits.subsection.heading')}</h3>
          {
            items.map((item, index) => {
              return (
                <div key={index}>
                  <h4>{t(`home.benefits.subsection.items.${index}.heading`)}</h4>
                  <p className="small">{t(`home.benefits.subsection.items.${index}.description`)}</p>
                </div>
              )
            })
          }
        </div>

      </div>

      <SocialButtons/>
    </Section>
  )
}
