import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import { Section } from '../common/section';

export const Services: React.FC = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    fragment serviceImage on File {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    query {
      face1: file(relativePath: {eq: "services/face-1.jpg"}) { ...serviceImage }
      face2: file(relativePath: {eq: "services/face-2.jpg"}) { ...serviceImage }
      face3: file(relativePath: {eq: "services/face-3.jpg"}) { ...serviceImage }
      face4: file(relativePath: {eq: "services/face-4.jpg"}) { ...serviceImage }
      face5: file(relativePath: {eq: "services/face-5.jpg"}) { ...serviceImage }
      face6: file(relativePath: {eq: "services/face-6.jpg"}) { ...serviceImage }
      face7: file(relativePath: {eq: "services/face-7.jpg"}) { ...serviceImage }
      face8: file(relativePath: {eq: "services/face-8.jpg"}) { ...serviceImage }
    }
  `)

  return (
      <Section className="services">
        <h2>{t(`home.services.heading`)}</h2>
        <ul>
          <li><Img fluid={data.face1.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face2.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face3.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face4.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face5.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face6.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face7.childImageSharp.fluid} /></li>
          <li><Img fluid={data.face8.childImageSharp.fluid} /></li>
        </ul>
      </Section>
  )
}
