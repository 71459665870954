import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
// @ts-ignore
import GoogleMapReact from 'google-map-react';
import {Section} from "../common/section";
import {Marker, PlaceProps} from "./map/marker";
import {fetchUtils} from "../common/utils/fetch_util";

/**
 * Sanitization map
 * @constructor
 */
export const Map: React.FC = () => {
  const {t} = useTranslation();
  const [places, setPlaces] = useState<PlaceProps[]>([]);

  // let places: PlaceProps[] = [];

  useEffect(() => {

    fetchUtils.getData(`${process.env.GATSBY_BACKEND}/sanitizations`)
      .then((data) => {
        setPlaces(data.map((place, index) => {
          console.log(place);
          return { name: "demo", lat: place.location.latitude, lng: place.location.longitude, show: false }
        }))
      })
      .catch((e) => {
        console.log('fetch failed');
      })
    // setPlaces([
    //   {lat: 37.9838, lng: 23.7275, name: "demo", show: false},
    //   {lat: 37.9838, lng: 23.7275, name: "demo", show: false},
    //   {lat: 37.9838, lng: 23.7275, name: "demo", show: false},
    // ])
  }, [])

  const handleClick = useCallback((key) => {
    const index = places.findIndex((e) => e.id === key);
    places[index].show = !places[index].show; // eslint-disable-line no-param-reassign
    setPlaces(places);
  }, []);

  // const getInfoWindowString = (place) => `
  //   <div>
  //     <div style="font-size: 16px;">
  //       ${place.name}
  //     </div>
  //     <div style="font-size: 14px;">
  //       <span style="color: grey;">
  //       100%
  //       </span>
  //     </div>
  //   </div>`;

  const getInfoWindowString = (place) => {

    const {lat, lng, name, rating, types, price_level, opening_hours} = place;

    return (
      <div className="info-window">
        <div>
          <div style={{fontSize: 16}}>
            {name}
          </div>
        </div>
        <div style={{fontSize: 14}}>
        <span style={{color: 'grey'}}>
          {rating}
          {' '}
        </span>
          <span style={{color: 'orange'}}>
          {String.fromCharCode(9733).repeat(Math.floor(rating))}
        </span>
          <span style={{color: 'lightgrey'}}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(rating))}
        </span>
        </div>
      </div>
    )
  }

  // const handleApiLoaded = (map, maps, places) => {
  //   const markers = [];
  //   const infowindows = [];
  //
  //   places.forEach((place) => {
  //     markers.push(new maps.Marker({
  //       position: {
  //         lat: place.lat,
  //         lng: place.lng,
  //       },
  //       map,
  //     }));
  //
  //     infowindows.push(new maps.InfoWindow({
  //       content: getInfoWindowString(place),
  //     }));
  //   });
  //
  //   console.log(`places: ${places}`)
  //
  //   markers.forEach((marker, i) => {
  //     marker.addListener('click', () => {
  //       infowindows[i].open(map, marker);
  //     });
  //   });
  // };

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

// Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

// Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    <Section className="map">
      <h2>{t('home.map.heading')}</h2>
      {/*container*/}
      <div style={{height: '700px', width: '100%'}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: process.env.GATSBY_GOOGLE_MAPS_KEY}}
          defaultCenter={{lat: 38.9838, lng: 22.7275}}
          defaultZoom={7}
          // yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={({map, maps}) => apiIsLoaded(map, maps, places)}
          // onChildClick={handleClick}
        >
          {places?.map((place, index) => (
            <Marker
              place={place}
              key={index}
              lat={place.lat}
              lng={place.lng}
              text={place.name}
            />
          ))}
        </GoogleMapReact>
        {/*        <div className="window">
          <div>{places[0].image}</div>
          <div>{places[0].name}
            - {places[0].datetime}
          </div>
          <div>{places[0].device}</div>
        </div>*/}
      </div>
    </Section>
  )
}
