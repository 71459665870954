import React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";

export const Callout: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div className="callout">
      <ul className="badges">
        <li className="uvc">{t("home.introduction.subsection.one")}</li>
        <li className="disinfection">{t("home.introduction.subsection.two")}</li>
        <li className="covid">{t("home.introduction.subsection.three")}</li>
      </ul>
    </div>
  )
};