import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../common/section";
import {Link} from "gatsby-plugin-react-i18next";

export const Solutions: React.FC = () => {
  const {t} = useTranslation();

  return (
      <Section className="solutions">
        <h2>{t('home.solutions.heading')}</h2>

        <ul className="products-list">
          <li className="skeptron">
            <h3 className="list-title">{t('home.solutions.items.0.heading')}</h3>
            <p>{t('home.solutions.items.0.description')}</p>
            <Link className="button" to="/products/skeptron">{t('home.solutions.items.0.button')}</Link>
          </li>
          <li className="hyperion">
            <h3 className="list-title">{t('home.solutions.items.1.heading')}</h3>
            <p>{t('home.solutions.items.1.description')}</p>
            <Link className="button" to="/products/hyperion">{t('home.solutions.items.1.button')}</Link>
          </li>
          <li className="special">
            <h3 className="list-title">{t('home.solutions.items.2.heading')}</h3>
            <p>{t('home.solutions.items.2.description')}</p>
            <Link className="button inactive" onClick={ (event) => event.preventDefault()} to="">{t('home.solutions.items.2.button')}</Link>
           
          </li>
        </ul>
      </Section>
  )
}
