import React from "react";
import {InfoWindow} from "./info_window";
import "./marker.scss";

interface MarkerProps {
  place: PlaceProps;
}

export interface PlaceProps {
  show: boolean;
  name: string;
  image?: string;
  device?: string;


  formatted_address?: string;
  rating?: number;
  types?: string[];
  price_level?: number;
  opening_hours?: string;
  latitude: number;
  longitude: number;
}

export const Marker: React.FC<MarkerProps> = ({place, onClick = null, ...rest}) => {

  const {show, name} = place;

  return (
    <>
      <div
        className="marker-wrapper"
        role="img text"
        alt={name}
        onClick={onClick}
      >
        <div className="pin"/>
        <div className="pulse"/>
      </div>
      {show && <InfoWindow place={place}/>}
    </>
  )
}