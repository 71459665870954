import React from "react"
import {Introduction} from "../components/home/introduction";
import {Solutions} from "../components/home/solutions";
import {Carousel} from "../components/home/carousel";
import {Applications} from "../components/home/applications";
import {Benefits} from "../components/home/benefits";
import {Map} from "../components/home/map";
import {Services} from "../components/home/services";
import {NormalLayout} from "../components/layouts/normal_layout";
import {SEO} from "../components/seo";
import {Callout} from "../components/home/callout";

const Home: React.FC = () => {
  return (
      <NormalLayout type="home">
        <SEO title="UVTech | Rendev" description="UVTech | Rendev"/>
        <Introduction/>
        <Callout />
        <Solutions/>
        <Carousel/>
        <Applications/>
        <Benefits/>
        <Map/>
        <Services/>
      </NormalLayout>
  );
}

export default Home;