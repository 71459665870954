import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../common/section";

export const Applications: React.FC = () => {

  const {t} = useTranslation();
  const items = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  return (
      <Section>
        <p className="subheading">{t('home.applications.subheading')}</p>
        <h2>{t('home.applications.heading')}</h2>
        <p className="description">{t('home.applications.description')}</p>

        <ul className="applications-list">
          {
            items.map((item, index) => {
              return (
                  <li key={index} className={t(`home.applications.items.${index}.class`)}>
                    <h3>{t(`home.applications.items.${index}.heading`)}</h3>
                    <p className="small">{t(`home.applications.items.${index}.description`)}</p>
                  </li>
              )})
          }

        </ul>
      </Section>
  )
}
